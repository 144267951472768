/* eslint-disable @typescript-eslint/naming-convention */
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

import { Button } from './button';

export const CloseButton = {
    baseStyle: (props: StyleFunctionProps) => ({
        ...Button.baseStyle(props),
        borderRadius: '100%',
        backgroundColor: 'white'
    }),
    variants: {
        ...Button.variants,
        outline: (props: StyleFunctionProps) => {
            const {
                blue, neutral600, primary600
            } = props.theme.app;

            return ({
                ...Button.variants.outline(props),
                border: `1px solid ${blue}`,
                color: neutral600,
                _active: {
                    bg: primary600,
                    color: 'white'
                }
            });
        }
    }
};

import React, {
    createContext, PropsWithChildren, useRef, useState
} from 'react';

import { ActivityKeypad } from './activity-keypad';
import { KeypadContextType } from './activity-keypad.types';

export const KeypadContext = createContext<KeypadContextType | null>(null);

export const KeypadProvider = ({ children }: PropsWithChildren) => {
    const [isKeypadBtnDisabled, setIsKeypadBtnDisabled] = useState(true);
    const [showKeypadBtn, setShowKeypadBtn] = useState<boolean>(false);
    const [showKeypad, setShowKeypad] = useState<boolean>(true);
    const keypadRef = useRef<HTMLDivElement>(null);
    const [questionBodyElement, setQuestionBodyElement] = useState<HTMLDivElement | null>(null);
    const questionBodyRef: KeypadContextType['questionBodyRef'] = (node) => {
        setQuestionBodyElement(node);
    };
    const keypadContextValue = {
        isKeypadBtnDisabled,
        setIsKeypadBtnDisabled,
        showKeypad,
        setShowKeypad,
        showKeypadBtn,
        setShowKeypadBtn,
        keypadRef,
        questionBodyRef,
        questionBodyElement
    };

    return (
        <KeypadContext.Provider value={keypadContextValue}>
            <ActivityKeypad />
            {children}
        </KeypadContext.Provider>
    );
};

/* eslint-disable import/order */
/* eslint-disable padding-line-between-statements */
import {
    QuestionProgressState, STUDENT_STATES
} from '../../../helpers/enums';
import {
    Action, ActionWithoutPayload
} from '../../../types/common.types';

export const GET_QUESTIONS_DETAILS_REQUESTED = 'GET_QUESTIONS_DETAILS_REQUESTED';
export const GET_QUESTIONS_DETAILS_SUCCESS = 'GET_QUESTIONS_DETAILS_SUCCESS';
export const GET_QUESTIONS_DETAILS_FAILURE = 'GET_QUESTIONS_DETAILS_FAILURE';

export const GET_STRAND_UOW_REQUESTED = 'GET_STRAND_UOW_REQUESTED';
export const GET_STRAND_UOW_SUCCESS = 'GET_STRAND_UOW_SUCCESS';
export const GET_STRAND_UOW_FAILURE = 'GET_STRAND_UOW_FAILURE';

export const GET_QUESTION_REQUESTED = 'GET_QUESTION_REQUESTED';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';

export const SAVE_QUESTION_REQUESTED = 'SAVE_QUESTION_REQUESTED';
export const SAVE_QUESTION_SUCCESS = 'SAVE_QUESTION_SUCCESS';
export const SAVE_QUESTION_FAILURE = 'SAVE_QUESTION_FAILURE';

export const LOAD_NEXT_QUESTION = 'LOAD_NEXT_QUESTION';

export const RESET_ACTIVITY = 'RESET_ACTIVITY';

export const SET_STUDENT_STATE = 'SET_STUDENT_STATE';

export const SET_MILESTONE_COMPLETE = 'SET_MILESTONE_COMPLETE';

export const DISMISS_ERROR = 'DISMISS_ERROR';

export const IS_OFFLINE = 'IS_OFFLINE';

export const IS_WORKED_SOLUTION_ACTIVATED = 'IS_WORKED_SOLUTION_ACTIVATED';

export const SET_READ_ALOUD_ENABLED = 'SET_READ_ALOUD_ENABLED';

export const SET_READ_ALOUD_LOCALE = 'SET_READ_ALOUD_LOCALE';
export const SET_PLAY_CORRECT_STARS = 'SET_PLAY_CORRECT_STARS';
export const SET_QUESTION_PROGRESS_STATE = 'SET_QUESTION_PROGRESS_STATE';

export const SET_SERVER_ERROR_INTERCEPTED = 'SET_SERVER_ERROR_INTERCEPTED';

export type QuestionActions =
    | GetQuestionsDetailsRequestAction
    | GetQuestionsDetailsFailureAction
    | GetQuestionsDetailsSuccessAction
    | GetStrandUowRequestAction
    | GetStrandUowFailureAction
    | GetStrandUowSuccessAction
    | GetQuestionRequestAction
    | GetQuestionFailureAction
    | GetQuestionSuccessAction
    | SaveQuestionFailureAction
    | SaveQuestionSuccessAction
    | SaveQuestionRequestAction
    | LoadNextQuestionAction
    | ResetActivityAction
    | SetStudentStateAction
    | SetMilestoneCompleteAction
    | DismissErrorAction
    | IsOfflineAction
    | IsWorkedSolutionActivatedAction
    | SetReadAloudEnabledAction
    | SetReadAloudLocaleAction
    | SetPlayCorrectStarsAction
    | SetQuestionProgressStateAction
    | SetServerErrorIntercepted
    ;

import {
    QuestionSave,
    QuestionSuccess,
    QuestionsDetails,
    StrandUowSuccess
} from '../sub-components/question-activity/question-activity.types';
/*
 *  GET QUESTIONS TOTALS
 * */

export type GetQuestionsDetailsSuccessAction = Action<typeof GET_QUESTIONS_DETAILS_SUCCESS, {
    response: QuestionsDetails
}>;

export type GetQuestionsDetailsRequestAction = {
    type: typeof GET_QUESTIONS_DETAILS_REQUESTED
} & ActionWithoutPayload;

export type GetQuestionsDetailsFailureAction = {
    type: typeof GET_QUESTIONS_DETAILS_FAILURE
} & ActionWithoutPayload;

export type QuestDetailsRequest = {
    questionSetId: string
    courseId: string
    uowId: string
    assignmentId?: string
    assignmentType?: string
    classId?: string
};

/*
 *  GET STRAND UOW
 * */

export type GetStrandUowSuccessAction = Action<typeof GET_STRAND_UOW_SUCCESS, {
    response: StrandUowSuccess
}>;

export type GetStrandUowRequestAction = {
    type: typeof GET_STRAND_UOW_REQUESTED
} & ActionWithoutPayload;

export type GetStrandUowFailureAction = {
    type: typeof GET_STRAND_UOW_FAILURE
} & ActionWithoutPayload;

/*
 *  GET QUESTION
 * */

export type GetQuestionSuccessAction = Action<typeof GET_QUESTION_SUCCESS, {
    response: QuestionSuccess
}>;

export type GetQuestionRequestAction = {
    type: typeof GET_QUESTION_REQUESTED
} & ActionWithoutPayload;

export type GetQuestionFailureAction = {
    type: typeof GET_QUESTION_FAILURE
} & ActionWithoutPayload;

export type IsOfflineAction = {
    type: typeof IS_OFFLINE
    isOffline: boolean
};

export type SetReadAloudEnabledAction = {
    type: typeof SET_READ_ALOUD_ENABLED
    readAloudEnabled: boolean
};

export type SetReadAloudLocaleAction = {
    type: typeof SET_READ_ALOUD_LOCALE
    readAloudLocale: string
};

/*
 *  SAVE QUESTION
 * */

export type SaveQuestionFailureAction = {
    type: typeof SAVE_QUESTION_FAILURE
} & ActionWithoutPayload;

export type SaveQuestionSuccessAction = Action<typeof SAVE_QUESTION_SUCCESS, {
    response: QuestionSave
}>;

export type SaveQuestionRequestAction = {
    type: typeof SAVE_QUESTION_REQUESTED
} & ActionWithoutPayload;

/*
 *  LOAD QUESTION
 * */
export type LoadNextQuestionAction = {
    type: typeof LOAD_NEXT_QUESTION
} & ActionWithoutPayload;

export type ResetActivityAction = {
    type: typeof RESET_ACTIVITY
} & ActionWithoutPayload;

export type SetStudentStateAction = {
    type: typeof SET_STUDENT_STATE
    studentState: STUDENT_STATES
};

export type SetMilestoneCompleteAction = {
    type: typeof SET_MILESTONE_COMPLETE
    milestoneComplete: boolean
};

export type DismissErrorAction = {
    type: typeof DISMISS_ERROR
} & ActionWithoutPayload;

/*
 *  WORKED SOLUTION
 * */

export type IsWorkedSolutionActivatedAction = ({
    type: typeof IS_WORKED_SOLUTION_ACTIVATED
    workedSolution: boolean
});

/*
* PLAY CORRECT STARS
* */
export type SetPlayCorrectStarsAction = {
    type: typeof SET_PLAY_CORRECT_STARS
} & ActionWithoutPayload;

export type SetQuestionProgressStateAction = {
    type: typeof SET_QUESTION_PROGRESS_STATE
    questionProgressState: QuestionProgressState
};

/*
* SERVER ERROR INTERCEPTED
* */
export type SetServerErrorIntercepted = {
    type: typeof SET_SERVER_ERROR_INTERCEPTED
    payload: Record<string, string | number> | undefined
};

import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalContent,
    ModalBody,
    Button,
    ModalFooter,
    useTheme,
    HStack,
    Box
} from '@chakra-ui/react';
import React, { useRef } from 'react';

import { TempSpinnerWrapper } from '../../../containers/question-container/sub-components/temp-poc/temp-spinner.styled';
import { lokalisation } from '../../../helpers/lokalisation';
import { useExitApp } from '../../../hooks/use-exit-app';
import { useAudioEffectsContext } from '../../audio/provider/audio-effects-provider';
import { ModalExitWithoutCompletingProps } from './exit-without-completing.types';

const ExitWithoutCompletingModal = ({
    isOpen,
    onClose
}: ModalExitWithoutCompletingProps) => {
    const { exitWithoutCompleting } = lokalisation();
    const theme = useTheme();
    const { playClick } = useAudioEffectsContext();
    const handleOnClose = () => {
        playClick();
        onClose();
    };
    const {
        exiting,
        redirect
    } = useExitApp();
    const handleExit = async () => {
        playClick();
        if (!exiting) {
            redirect();
        }
    };
    const initialRef = useRef<HTMLDivElement>(null);

    return (
        <Modal
            size={'full'}
            data-testid={'exit-without-completing-modal'}
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={!exiting}
            initialFocusRef={initialRef}
        >
            <ModalHeader
                ref={initialRef}
                tabIndex={-1}
            />
            <ModalOverlay />
            <ModalContent
                border={'8px'}
                borderColor={theme.app.whiteAlpha30}
                bg={theme.app.whiteAlpha30}
                borderRadius={'40px'}
                mt={{
                    tabletLandscape: '120px',
                    md: '80px',
                    base: '61px'
                }}
                w={{
                    tabletLandscape: '640px',
                    sm: '600px',
                    base: '343px'
                }}
            >
                <ModalBody
                    data-testid={'modal-body'}
                    bg={'white'}
                    borderRadius={'34px 34px 0 0'}
                    p={0}
                >
                    <Box
                        pt={{
                            tabletLandscape: '48px',
                            base: '40px'
                        }}
                        px={{
                            tabletLandscape: '80px',
                            base: '24px'
                        }}
                        textAlign={'center'}
                        fontSize={{
                            tabletLandscape: '30px',
                            md: '28px',
                            base: '24px'
                        }}
                        letterSpacing={'-0.4px'}
                        lineHeight={'140%'}
                        whiteSpace={'pre-wrap'}
                    >
                        {exitWithoutCompleting.para1}
                        <br />
                        {exitWithoutCompleting.para2}
                    </Box>
                </ModalBody>
                <ModalFooter
                    bg={'white'}
                    pb={{
                        tabletLandscape: '48px',
                        base: '40px'
                    }}
                    px={{
                        tabletLandscape: '80px',
                        base: '24px'
                    }}
                    pt={{
                        tabletLandscape: '48px',
                        base: '44px'
                    }}
                    borderRadius={'0 0 34px 34px'}
                >
                    <HStack
                        spacing={{
                            tabletLandscape: '40px',
                            base: '24px'
                        }}
                        align={'center'}
                        justifyContent={'center'}
                        w={'100%'}
                    >
                        <Button
                            transitionDuration={'0ms'}
                            color={theme.app.blue02}
                            borderColor={theme.app.primary300}
                            variant={'outline'}
                            colorScheme={'footer-white'}
                            data-testid={'modal-close-button'}
                            className={'close-button'}
                            isDisabled={exiting}
                            onClick={handleOnClose}
                            px={{
                                tabletLandscape: '32px',
                                md: '16px'
                            }}
                            py={{
                                tabletLandscape: '8px',
                                md: '10px'
                            }}
                            w={{
                                tabletLandscape: '220px',
                                md: '200px',
                                base: '40%'
                            }}
                            minW={{
                                tabletLandscape: '220px',
                                md: '200px',
                                base: '134px'
                            }}
                            h={{
                                tabletLandscape: '48px',
                                md: '40px',
                                base: '40px'
                            }}
                            fontSize={'20px'}
                            fontWeight={'500'}
                        >
                            {exitWithoutCompleting.no}
                        </Button>
                        <Button
                            colorScheme={'primary'}
                            data-testid={'modal-exit-button'}
                            className={'exit-button'}
                            onClick={() => handleExit()}
                            px={{
                                tabletLandscape: '32px',
                                md: '16px'
                            }}
                            py={{
                                tabletLandscape: '8px',
                                md: '10px'
                            }}
                            w={{
                                tabletLandscape: '220px',
                                md: '200px',
                                base: '40%'
                            }}
                            minW={{
                                tabletLandscape: '220px',
                                md: '200px',
                                base: '134px'
                            }}
                            h={{
                                tabletLandscape: '48px',
                                md: '40px',
                                base: '40px'
                            }}
                            fontSize={'20px'}
                            fontWeight={'500'}
                        >
                            {exiting || exitWithoutCompleting.yes}
                            {exiting && (
                                <TempSpinnerWrapper data-testid={'spinner'}>
                                    <div
                                        className={'lds-ellipsis'}
                                    >
                                        <div />
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                </TempSpinnerWrapper>
                            )}
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ExitWithoutCompletingModal;

import {
    useEffect, useState
} from 'react';
import {
    useDispatch, useSelector
} from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../../../app/store/store.types';
import {
    getQuestion,
    getQuestionsDetails,
    getStrandUow,
    setMilestoneComplete,
    setQuestionProgress
} from '../../../containers/question-container/store/question-actions';
import {
    QuestionProgressState, QuestionSetType, TransitionScreen
} from '../../../helpers/enums';
import {
    getQueryParamValues, isDefined
} from '../../../helpers/utils';
import { ROUTES } from '../../routes';

export const useAppInitialization = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const {
        questName, isLoading, isError, progress, milestoneComplete, questionSetType
    } = activityState;
    const [transitionScreen, setTransitionScreen] = useState<TransitionScreen>();
    const [dataInitialised, setDataInitialised] = useState<boolean>(false);

    /*
     * GET INITIAL DATA TO DECIDE ON ROUTE
     */
    useEffect(() => {
        if (isLoading || isError) {
            return;
        }

        const queryParams = getQueryParamValues(['questionSetId', 'courseId', 'uowId', 'assignmentId', 'classId']);
        const {
            questionSetId, courseId = '', uowId = '', assignmentId, classId
        } = queryParams;

        if (courseId && uowId) {
            dispatch(getStrandUow(courseId, uowId));
        }

        if (questionSetId && !isDefined(questName)) {
            dispatch(getQuestionsDetails({
                questionSetId,
                courseId,
                uowId,
                assignmentId,
                classId
            }));
        } else if (questionSetId && isDefined(questName) && !isDefined(progress)) {
            dispatch(getQuestion(questionSetId, assignmentId != null ? parseInt(assignmentId) : 0));
        }
    }, [dispatch, isError, isLoading, progress, questName]);
    /*
     * DATA INITIALISED
     */
    useEffect(() => {
        if(isDefined(progress) && !dataInitialised) {
            setDataInitialised(true);
        }
    }, [dataInitialised, progress]);
    /*
     * CURRENT MILESTONE
     */
    useEffect(() => {
        const {
            stepsCompleted, attempt, isQuestCompleted
        } = progress || {};

        if (dataInitialised) {
            if (!isDefined(milestoneComplete)) {
                if (questionSetType !== QuestionSetType.questiconActivity) {
                    if (isQuestCompleted) {
                        setTransitionScreen(TransitionScreen.diagnosticsSummaryScreen);
                        dispatch(setMilestoneComplete(true));
                    } else {
                        if (questionSetType === QuestionSetType.diagnostics && stepsCompleted === 0) {
                            setTransitionScreen(TransitionScreen.diagnosticsStartScreen);
                        } else if (questionSetType === QuestionSetType.unitTest && stepsCompleted === 0) {
                            setTransitionScreen(TransitionScreen.unitTestIntroScreen);
                        }
                        dispatch(setMilestoneComplete(false));
                    }
                } else if (attempt === 1) {
                    switch (stepsCompleted) {
                    case 0:
                        setTransitionScreen(TransitionScreen.startScreen);
                        dispatch(setMilestoneComplete(false));
                        break;
                    case 5:
                        setTransitionScreen(TransitionScreen.startReasoning);
                        dispatch(setMilestoneComplete(true));
                        break;
                    case 8:
                        setTransitionScreen(TransitionScreen.startProblemSolving);
                        dispatch(setMilestoneComplete(true));
                        break;
                    case 10:
                        setTransitionScreen(TransitionScreen.summaryScreen);
                        dispatch(setMilestoneComplete(true));
                        break;
                    default:
                        setTransitionScreen(undefined);
                        dispatch(setMilestoneComplete(false));
                        dispatch(setQuestionProgress(QuestionProgressState.resetUnanswered));
                    }
                } else if (isQuestCompleted){
                    setTransitionScreen(TransitionScreen.summaryScreen);
                    dispatch(setMilestoneComplete(true));
                } else {
                    setTransitionScreen(undefined);
                    dispatch(setMilestoneComplete(false));
                    dispatch(setQuestionProgress(QuestionProgressState.resetUnanswered));
                }
            }
        }
    }, [dataInitialised, dispatch, milestoneComplete, progress, questionSetType]);
    /*
     * SHOW INITIAL SCREEN TYPE
     */
    useEffect(() => {
        if (isDefined(history) && isDefined(milestoneComplete)) {
            if (isDefined(transitionScreen)) {
                history.replace(`${ROUTES.milestoneTransition}/${transitionScreen}`);
            } else if (dataInitialised && questionSetType === QuestionSetType.questiconActivity) {
                history.replace(ROUTES.questionConsole);
            } else if (dataInitialised &&
                (questionSetType === QuestionSetType.diagnostics ||
                    questionSetType === QuestionSetType.unitTest)) {
                history.replace(ROUTES.diagnostics);
            }
        }
    }, [history, milestoneComplete, dataInitialised, transitionScreen, questionSetType]);

    return {
        isLoading,
        isError
    };
};

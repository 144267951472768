import { ThemeProps } from '@3plearning/question-components-library';
import {
    Box, Hide, IconButton, Show, useTheme
} from '@chakra-ui/react';
import React, {
    MouseEventHandler, TouchEventHandler, useEffect, useRef, useState,
    MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent, useCallback, useContext
} from 'react';

import AppImage from '../../../../components/app-image/app-image';
import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import {
    KeypadContextType, Position
} from './activity-keypad.types';
import { KeypadContext } from './keypad-provider';

export const ActivityKeypad = () => {
    const {
        isKeypadBtnDisabled,
        setIsKeypadBtnDisabled,
        showKeypad,
        setShowKeypad,
        setShowKeypadBtn,
        keypadRef,
        questionBodyElement
    } = useContext(KeypadContext) as KeypadContextType;
    const keypadSpacing = 8;
    const keypadContainerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [translatedPosition, setTranslatedPosition] = useState<Position>({
        left: 0,
        top: 0
    });
    const [referencePosition, setReferencePosition] = useState<Position>({
        left: 0,
        top: 0
    });
    const { playClick } = useAudioEffectsContext();
    const onCloseKeypad = () => {
        playClick();
        setShowKeypad(false);
    };
    const handleMouseDown: MouseEventHandler & TouchEventHandler = (event) => {
        const node = keypadContainerRef.current as HTMLElement;
        const {
            clientX, clientY
        } = (event as ReactTouchEvent).changedTouches ? (event as ReactTouchEvent).changedTouches[0] : (event as ReactMouseEvent);

        setReferencePosition({
            left: clientX - node?.offsetLeft,
            top: clientY - node?.offsetTop
        });
        setIsDragging(true);
        event.stopPropagation();
        event.preventDefault();
    };
    const theme: ThemeProps = useTheme();
    const positionKeypad = useCallback(() => {
        const isTabletPortrait = parseInt(theme.breakpoints.lg, 10) > window.innerWidth;
        const isMobile = parseInt(theme.breakpoints.md, 10) > window.innerWidth ||
        (parseInt(theme.breakpoints.md, 10) > window.innerHeight && window.innerWidth < parseInt(theme.breakpoints.lg, 10));

        if (translatedPosition.left === 0 && translatedPosition.top === 0) {
            // timeout is needed to allow the keypad to render before positioning, and allow questionBody to finish sliding up on mobile
            setTimeout(() => {
                const questionBodyPosition = questionBodyElement?.getBoundingClientRect();

                if (
                    questionBodyPosition &&
                    !(questionBodyPosition.left === 0 && questionBodyPosition.top === 0) &&
                    keypadContainerRef.current
                ) {
                    let initialLeft = questionBodyPosition.left + questionBodyPosition.width + keypadSpacing;
                    let initialTop = questionBodyPosition.top + keypadSpacing;

                    if (isTabletPortrait) {
                        initialTop = questionBodyPosition.bottom - keypadSpacing - keypadContainerRef.current.clientHeight;
                    }

                    if (isMobile) {
                        initialTop =  window.innerHeight - keypadContainerRef.current.clientHeight;
                    }

                    if (initialLeft + keypadContainerRef.current.clientWidth > window.innerWidth) {
                        initialLeft = window.innerWidth - keypadContainerRef.current.clientWidth;
                    }

                    keypadContainerRef.current.style.left = `${initialLeft}px`;
                    keypadContainerRef.current.style.top = `${initialTop}px`;
                }
            }, isTabletPortrait ? 400 : 200);
        } else {
            if (keypadContainerRef.current) {
                const keypadHandle = keypadContainerRef.current.querySelector('.keypad-handle') as HTMLElement;
                const keypadHandleWidth = keypadHandle.clientWidth;
                const keypadHandleHeight = keypadHandle.clientHeight;

                let updatedLeft = translatedPosition.left;
                let updatedTop = translatedPosition.top;

                if (isTabletPortrait) {
                    const allowedHiddenArea = keypadHandleHeight / 2;

                    if (updatedLeft < 0) {
                        updatedLeft = 0;
                    } else if (updatedLeft + keypadHandleWidth > window.innerWidth) {
                        updatedLeft = window.innerWidth - keypadHandleWidth;
                    }

                    if (updatedTop + allowedHiddenArea < 0) {
                        updatedTop = -allowedHiddenArea;
                    } else if (updatedTop + allowedHiddenArea > window.innerHeight) {
                        updatedTop = window.innerHeight - allowedHiddenArea;
                    }
                } else {
                    const allowedHiddenArea = keypadHandleWidth / 2;

                    if (updatedLeft + allowedHiddenArea < 0) {
                        updatedLeft = -allowedHiddenArea;
                    } else if (updatedLeft + allowedHiddenArea > window.innerWidth) {
                        updatedLeft = window.innerWidth - allowedHiddenArea;
                    }

                    if (updatedTop < 0) {
                        updatedTop = 0;
                    } else if (updatedTop + keypadHandleHeight > window.innerHeight) {
                        updatedTop = window.innerHeight - keypadHandleHeight;
                    }
                }

                keypadContainerRef.current.style.left = `${updatedLeft}px`;
                keypadContainerRef.current.style.top = `${updatedTop}px`;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translatedPosition, isKeypadBtnDisabled, questionBodyElement, showKeypad]);

    useEffect(() => {
        const onWindowResize: ResizeObserverCallback = (entries) => {
            if (entries) {
                positionKeypad();
            }
        };
        const resizeObserver = new ResizeObserver(onWindowResize);
        const application = document.querySelector('.application');

        if (application) {
            resizeObserver.observe(application);
        }

        positionKeypad();

        return () => {
            resizeObserver.disconnect();
        };
    }, [positionKeypad]);

    useEffect(() => {
        const toggleKeypadButtonDisableState = (mutationList: MutationRecord[]) => {
            for(const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    if (mutation.removedNodes.length) {
                        setIsKeypadBtnDisabled(true);
                    } else if (mutation.addedNodes.length) {
                        setIsKeypadBtnDisabled(false);
                    }
                }
            }
        };
        const observer = new MutationObserver(toggleKeypadButtonDisableState);

        if (keypadRef.current) {
            observer.observe(keypadRef.current, { childList: true });
        }

        return () => {
            observer.disconnect();
        };
    }, [keypadRef, setIsKeypadBtnDisabled]);

    useEffect(() => {
        const toggleKeypadButton = (mutationList: MutationRecord[]) => {
            for(const mutation of mutationList) {
                if (mutation) {
                    setShowKeypadBtn(Boolean(questionBodyElement && questionBodyElement.querySelector('.math-inputbox')));
                    positionKeypad();
                }
            }
        };
        const observer = new MutationObserver(toggleKeypadButton);

        if (questionBodyElement) {
            observer.observe(questionBodyElement, {
                subtree: true,
                childList: true
            });
        }

        return () => {
            observer.disconnect();
        };
    }, [questionBodyElement, setShowKeypadBtn, positionKeypad]);

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent | TouchEvent) => {
            if (isDragging) {
                const {
                    clientX, clientY
                } = (event as TouchEvent).changedTouches ? (event as TouchEvent).changedTouches[0] : (event as MouseEvent);

                if (clientX !== 0) {
                    setTranslatedPosition({
                        left: clientX - referencePosition.left,
                        top: clientY - referencePosition.top
                    });
                }
            }
        };
        const handleMouseUp: EventListener = () => {
            setIsDragging(false);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('touchend', handleMouseUp);
            document.removeEventListener('touchcancel', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchend', handleMouseUp);
        document.addEventListener('touchcancel', handleMouseUp);
        document.addEventListener('touchmove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchend', handleMouseUp);
            document.removeEventListener('touchcancel', handleMouseUp);
            document.removeEventListener('touchmove', handleMouseMove);
        };
    }, [isDragging, referencePosition]);

    const keypadHiddenPostion = {
        left: -900,
        top: -900
    };

    return (
        <Box
            display={isKeypadBtnDisabled || !showKeypad ? 'none' : 'flex'}
            padding={{
                base: '10px',
                lg: '0 20px'
            }}
            boxSizing={'border-box'}
            position= {'fixed'}
            onMouseDown={event => event.preventDefault()}
            zIndex={'999'}
            flexDirection={{
                base: 'row',
                lg: 'column'
            }}
            backgroundColor={'white'}
            borderRadius={'20px'}
            filter={'drop-shadow(0px 12px 16px rgba(0, 25, 55, 0.16))'}
            border={'3px solid'}
            borderColor={'neutral.100'}
            ref={keypadContainerRef}
            data-testid={'keypad-container'}
            {...keypadHiddenPostion}
        >
            <Box
                data-testid={'keypad-drag-handle'}
                className={'keypad-handle'}
                pt={{
                    base: '15px',
                    lg: '20px'
                }}
                pr={{
                    base: '10px',
                    lg: '0'
                }}
                justifyContent={'space-between'}
                display={'flex'}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                cursor={isDragging? 'grabbing' : 'grab'}
            >
                <IconButton
                    minW={8}
                    h={8}
                    aria-label={'move keypad'}
                    colorScheme={'footer-white'}
                    variant={'outline'}
                    cursor={isDragging? 'grabbing' : 'grab'}
                    icon={<AppImage iconType={'dragIcon'} imageType={'svg'} />}
                    tabIndex={-1}
                />
                <Show above={'lg'}>
                    <IconButton
                        minW={8}
                        h={8}
                        aria-label={'close keypad'}
                        colorScheme={'footer-white'}
                        variant={'outline'}
                        icon={<AppImage iconType={'closeIcon'} imageType={'svg'} />}
                        onClick={onCloseKeypad}
                        onTouchStart={onCloseKeypad}
                        tabIndex={-1}
                    />
                </Show>
            </Box>
            <div
                ref={keypadRef}
                data-testid={'math-keypad'}
                style={{ position: 'relative' }}
            />
            <Hide above={'lg'}>
                <Box
                    pt={'15px'}
                    pl={'10px'}
                    justifyContent={'space-between'}
                    display={'flex'}
                >
                    <IconButton
                        minW={8}
                        h={8}
                        aria-label={'close keypad'}
                        colorScheme={'footer-white'}
                        variant={'outline'}
                        icon={<AppImage iconType={'closeIcon'} imageType={'svg'} />}
                        onClick={onCloseKeypad}
                        onTouchStart={onCloseKeypad}
                        tabIndex={-1}
                    />
                </Box>
            </Hide>
        </Box>
    );
};

import { Dispatch } from 'redux';

import { SaveQuestionRequest } from '../../../../api/types/__generated__/data-contracts';
import {
    QuestionProgressState, STUDENT_STATES
} from '../../../helpers/enums';
import { Resources } from '../../../resources/resources';
import {
    GET_QUESTION_FAILURE,
    GET_QUESTION_REQUESTED,
    GET_QUESTION_SUCCESS,
    GetQuestionFailureAction,
    GetQuestionRequestAction,
    GetQuestionSuccessAction,
    GET_QUESTIONS_DETAILS_FAILURE,
    GET_QUESTIONS_DETAILS_REQUESTED,
    GET_QUESTIONS_DETAILS_SUCCESS,
    GET_STRAND_UOW_FAILURE,
    GET_STRAND_UOW_REQUESTED,
    GET_STRAND_UOW_SUCCESS,
    GetQuestionsDetailsFailureAction,
    GetQuestionsDetailsRequestAction,
    GetQuestionsDetailsSuccessAction,
    LOAD_NEXT_QUESTION,
    LoadNextQuestionAction,
    RESET_ACTIVITY,
    ResetActivityAction,
    SAVE_QUESTION_FAILURE,
    SAVE_QUESTION_REQUESTED,
    SAVE_QUESTION_SUCCESS,
    SaveQuestionFailureAction,
    SaveQuestionRequestAction,
    SaveQuestionSuccessAction,
    SET_STUDENT_STATE,
    SetStudentStateAction,
    SET_MILESTONE_COMPLETE,
    SetMilestoneCompleteAction,
    DismissErrorAction,
    DISMISS_ERROR,
    IS_OFFLINE,
    IsOfflineAction,
    IsWorkedSolutionActivatedAction,
    IS_WORKED_SOLUTION_ACTIVATED,
    SetReadAloudEnabledAction,
    SET_READ_ALOUD_ENABLED,
    SetReadAloudLocaleAction,
    SET_READ_ALOUD_LOCALE,
    GetStrandUowFailureAction,
    GetStrandUowSuccessAction,
    GetStrandUowRequestAction,
    QuestDetailsRequest,
    SET_PLAY_CORRECT_STARS,
    SetPlayCorrectStarsAction,
    SET_QUESTION_PROGRESS_STATE,
    SetQuestionProgressStateAction,
    SET_SERVER_ERROR_INTERCEPTED,
    SetServerErrorIntercepted
} from './question-action-types';

/*
 * GET QUESTIONS TOTALS
 * */

export const getQuestionsDetailsFailure = (): GetQuestionsDetailsFailureAction =>
    ({ type: GET_QUESTIONS_DETAILS_FAILURE });

export const getQuestionsDetailsSuccess = (
    payload: GetQuestionsDetailsSuccessAction['payload']
): GetQuestionsDetailsSuccessAction => ({
    type: GET_QUESTIONS_DETAILS_SUCCESS,
    payload
});

export const getQuestionsDetailsRequest = (): GetQuestionsDetailsRequestAction =>
    ({ type: GET_QUESTIONS_DETAILS_REQUESTED });

export const getQuestionsDetails = ({
    questionSetId,
    courseId,
    uowId,
    assignmentId,
    classId
}: QuestDetailsRequest) => async (dispatch: Dispatch) => {
    dispatch(getQuestionsDetailsRequest());

    const questDetailsRequest = {
        questionSetId,
        courseId,
        uowId,
        assignmentId: assignmentId || 0,
        // questionDetails is used for self assignment only, so we can hardcode assignmentType to 1 (voluntary)
        assignmentType: '1',
        classId
    };

    try {
        const response = await Resources.getQuestionsDetails.post().withBody({ ...questDetailsRequest }).send();

        if (response.data && response.data.questName) {
            dispatch(getQuestionsDetailsSuccess({ response: response.data }));
        } else {
            dispatch(getQuestionsDetailsFailure());
        }
    } catch (e) {
        dispatch(getQuestionsDetailsFailure());
    }
};

/*
 *  GET STRAND UOW
 * */

export const getStrandUowFailure = (): GetStrandUowFailureAction =>
    ({ type: GET_STRAND_UOW_FAILURE });

export const getStrandUowSuccess = (
    payload: GetStrandUowSuccessAction['payload']
): GetStrandUowSuccessAction => ({
    type: GET_STRAND_UOW_SUCCESS,
    payload
});

export const getStrandUowRequest = (): GetStrandUowRequestAction =>
    ({ type: GET_STRAND_UOW_REQUESTED });

export const getStrandUow = (courseId: string, uowId: string) => async (dispatch: Dispatch) => {
    dispatch(getStrandUowRequest());

    try {
        const response = await Resources.getStrandUow.get({
            courseId,
            uowId
        }).send();

        if (response.data) {
            dispatch(getStrandUowSuccess({ response: response.data }));
        } else {
            dispatch(getStrandUowFailure());
        }
    } catch (e) {
        dispatch(getStrandUowFailure());
    }
};

/*
 *  GET QUESTION
 * */

export const getQuestionFailure = (): GetQuestionFailureAction => ({ type: GET_QUESTION_FAILURE });

export const getQuestionSuccess = (
    payload: GetQuestionSuccessAction['payload']
): GetQuestionSuccessAction => ({
    type: GET_QUESTION_SUCCESS,
    payload
});

export const getQuestionRequest = (): GetQuestionRequestAction => ({ type: GET_QUESTION_REQUESTED });

export const getQuestion = (questionSetId: string, assignmentId?: number) => async (dispatch: Dispatch) => {
    dispatch(getQuestionRequest());

    try {
        const response = await Resources.getQuestion.post().withBody({
            questionSetId,
            assignmentId
        }).send();

        if (response.data) {
            dispatch(getQuestionSuccess({ response: response.data }));
        } else {
            dispatch(getQuestionFailure());
        }
    } catch (e) {
        dispatch(getQuestionFailure());
    }
};

export const setIsOffline = (isOffline: boolean): IsOfflineAction => ({
    type: IS_OFFLINE,
    isOffline
});

export const setReadAloudEnabled = (readAloudEnabled: boolean): SetReadAloudEnabledAction => ({
    type: SET_READ_ALOUD_ENABLED,
    readAloudEnabled
});

export const setReadAloudValue = (readAloudLocale: string): SetReadAloudLocaleAction => ({
    type: SET_READ_ALOUD_LOCALE,
    readAloudLocale
});

/*
 *  SAVE QUESTION
 * */
export const saveQuestionFailure = (): SaveQuestionFailureAction => ({ type: SAVE_QUESTION_FAILURE });

export const saveQuestionSuccess = (
    payload: SaveQuestionSuccessAction['payload']
): SaveQuestionSuccessAction => ({
    type: SAVE_QUESTION_SUCCESS,
    payload
});

export const saveQuestionRequest = (): SaveQuestionRequestAction => ({ type: SAVE_QUESTION_REQUESTED });

export const saveQuestion = (saveData: SaveQuestionRequest) => async (dispatch: Dispatch) => {
    dispatch(saveQuestionRequest());

    try {
        const response = await Resources.saveQuestion.post().withBody({ ...saveData as object }).send();

        if (response.data) {
            dispatch(saveQuestionSuccess({ response: response.data }));
        } else {
            dispatch(saveQuestionFailure());
        }

        return response.data;
    } catch (e) {
        dispatch(saveQuestionFailure());
    }
};

/*
 *  LOAD QUESTION
 * */

export const loadNextQuestion = (): LoadNextQuestionAction => ({ type: LOAD_NEXT_QUESTION });

/*
 *  ACTIVITY
 * */

export const resetActivity = (): ResetActivityAction => ({ type: RESET_ACTIVITY });

export const setStudentState = (studentState: STUDENT_STATES): SetStudentStateAction => ({
    type: SET_STUDENT_STATE,
    studentState
});

export const setMilestoneComplete = (milestoneComplete: boolean): SetMilestoneCompleteAction => ({
    type: SET_MILESTONE_COMPLETE,
    milestoneComplete
});

export const dismissError = (): DismissErrorAction => ({ type: DISMISS_ERROR });

/*
 *  WORKED SOLUTION
 * */

export const activateWorkedSolution = (workedSolution: boolean): IsWorkedSolutionActivatedAction => ({
    type: IS_WORKED_SOLUTION_ACTIVATED,
    workedSolution
});

/*
 *  READ ALOUD
 * */

export const loadReadAloudLocale = (defaultReadAloudLocale: string, isRetry = false) => async (dispatch: Dispatch) => {
    try {
        const response = await Resources.getReadAloudLocale.get().send();

        if (response.data){
            dispatch(setReadAloudEnabled(response.data.isReadAloudEnabled));
            if (response.data.readAloudLocale) {
                dispatch(setReadAloudValue(response.data.readAloudLocale));
            } else {
                dispatch(setReadAloudValue(defaultReadAloudLocale));
            }
        }
    } catch (e) {
        if (!isRetry) {
            loadReadAloudLocale(defaultReadAloudLocale, true);
        }
    }
};

export const setReadAloudLocale = (readAloudLocale: string, isReadAloudEnabled: boolean, isRetry = false) => async (dispatch: Dispatch) => {
    dispatch(setReadAloudEnabled(isReadAloudEnabled));
    dispatch(setReadAloudValue(readAloudLocale));

    try {
        await Resources.postReadAloudLocale.post().withBody({
            isReadAloudEnabled,
            readAloudLocale
        }).raw().send();
    } catch (e) {
        if (!isRetry) {
            setReadAloudLocale(readAloudLocale, isReadAloudEnabled, true);
        }
    }
};
/*
* SET PLAY CORRECT STARS
* */

export const setPlayCorrectStars = (): SetPlayCorrectStarsAction => ({ type: SET_PLAY_CORRECT_STARS });

export const setQuestionProgress = (questionProgressState: QuestionProgressState) : SetQuestionProgressStateAction => ({
    type: SET_QUESTION_PROGRESS_STATE,
    questionProgressState
});

export const setServerErrorIntercepted = (payload: Record<string, string | number> | undefined): SetServerErrorIntercepted => ({
    type: SET_SERVER_ERROR_INTERCEPTED,
    payload
});

import {
    dispatchStopReadAloudEvent, ModalClickviewVideo
} from '@3plearning/question-components-library';
import {
    useDisclosure, Hide, Show, Box
} from '@chakra-ui/react';
import React, {
    useState,
    useEffect,
    useRef
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';

import { AppState } from '../../../../app/store/store.types';
import AppImage from '../../../../components/app-image/app-image';
import { useAudioEffectsContext } from '../../../../components/audio/provider/audio-effects-provider';
import { QuestionAttempts } from '../../../../components/question-attempts/question-attempts';
import { lokalisation } from '../../../../helpers/lokalisation';
import { isDefined } from '../../../../helpers/utils';
import { useWithMobile } from '../../../../hooks/use-with-mobile';
import { ContentType } from '../../../../routes/milestone-transition/sub-components/intro-screen/intro-screen.types';
import { activateWorkedSolution } from '../../store/question-actions';
import { AttemptStatus } from '../attempt-status/attempt-status';
import { useFooterResponsiveStyles } from './question-footer.hooks';
import {
    QuestionFooterContainer, IconSupportButton, SupportButton, SubmitButton, CloseIcon
} from './question-footer.styled';
import { QuestionFooterProps } from './question-footer.types';

const QuestionFooter = ({
    conceptRefreshUrl,
    footerBtnLabel,
    checkButtonDisabled,
    showNextQuestion,
    validateAnswer,
    validation,
    workedSolution,
    footerBtnColorScheme,
    isDiagnostic = false,
    isWorkedSolutionActivated = false
}: QuestionFooterProps) => {
    const {
        landscape,
        isMobile,
        isTablet
    } = useWithMobile();
    const activityState = useSelector<AppState, AppState['activityState']>(state => state.activityState);
    const { studentState } = activityState;
    const { footerResponsiveStyles } = useFooterResponsiveStyles();
    const checkBtnRef = useRef<HTMLButtonElement>(null);
    const { playClick } = useAudioEffectsContext();
    const { introTxt } = lokalisation();
    const dispatch = useDispatch();
    const [workedSolutionState, setWorkedSolutionState] = useState({
        buttonDisabled: true,
        buttonPulse: ''
    });
    const workedSolutionHandler = () => {
        playClick();
        setWorkedSolutionState({
            buttonDisabled: false,
            buttonPulse: ''
        });
        dispatch(activateWorkedSolution(!isWorkedSolutionActivated));
        dispatchStopReadAloudEvent();
        window.dataLayer.push({ event: 'worked_solution' });
    };
    const { ...chakraModalProps } = useDisclosure();
    const conceptRefreshHandler = () => {
        playClick();
        chakraModalProps.onOpen();
        dispatchStopReadAloudEvent();
        window.dataLayer.push({ event: 'concept_refresh' });
    };

    useEffect(() => {
        if (!validation) {
            setWorkedSolutionState({
                buttonDisabled: true,
                buttonPulse: ''
            });
        }
        else if (validation?.isCorrect) {
            setWorkedSolutionState({
                buttonDisabled: false,
                buttonPulse: ''
            });
        } else if (!validation?.isCorrect) {
            setWorkedSolutionState({
                buttonDisabled: false,
                buttonPulse: 'worked-solution-pulse'
            });
        }
    }, [validation]);

    // this useEffect needs to be separate
    useEffect(() => {
        if(isDefined(validation)) {
            checkBtnRef.current?.focus();
        }
    }, [validation]);

    const onCloseModalHandler = () => {
        playClick();
        chakraModalProps.onClose();
    };

    return (
        <Box
            as={QuestionFooterContainer}
            {...footerResponsiveStyles()}
            position={(isMobile || (landscape && isMobile)) && !isTablet ? 'fixed' : 'unset'}
            display={studentState === 'resume' ? 'none' : 'flex'}
            className={'question-footer'}
        >
            <div className={'left-footer'}>
                {isDiagnostic ? <QuestionAttempts /> : <>
                    <Hide below={'phonePortrait'}>
                        <SupportButton
                            leftIcon={<AppImage imageType={'svg'} iconType={'conceptRefreshIcon'} />}
                            variant={'outline'}
                            colorScheme={'footer-white'}
                            isDisabled={!conceptRefreshUrl}
                            data-testid={'concept-refresh-button'}
                            onClick={conceptRefreshHandler}
                            tabIndex={-1}
                        >
                            {introTxt.conceptRefresh}
                        </SupportButton>
                        <SupportButton
                            leftIcon={isWorkedSolutionActivated
                                ? <CloseIcon><AppImage imageType={'svg'} iconType={'closeIcon'} /></CloseIcon>
                                : <AppImage imageType={'svg'} iconType={'workedSolutionIcon'} />
                            }
                            variant={'outline'}
                            colorScheme={'footer-white'}
                            isDisabled={workedSolutionState.buttonDisabled}
                            className={workedSolutionState.buttonPulse}
                            data-testid={'worked-solution-button'}
                            ml={5}
                            onClick={workedSolutionHandler}
                            isActive={isWorkedSolutionActivated}
                            tabIndex={-1}
                        >
                            {workedSolution}
                        </SupportButton>
                    </Hide>
                    <Show below={'phonePortrait'}>
                        <IconSupportButton
                            variant={'outline'}
                            colorScheme={'footer-white'}
                            isDisabled={!conceptRefreshUrl}
                            data-testid={'concept-refresh-button'}
                            onClick={conceptRefreshHandler}
                            tabIndex={-1}
                        >
                            <AppImage imageType={'svg'} iconType={'conceptRefreshIcon'} />
                        </IconSupportButton>
                        <IconSupportButton
                            variant={'outline'}
                            colorScheme={'footer-white'}
                            isDisabled={workedSolutionState.buttonDisabled}
                            className={workedSolutionState.buttonPulse}
                            data-testid={'worked-solution-button'}
                            ml={'10px'}
                            onClick={workedSolutionHandler}
                            isActive={isWorkedSolutionActivated}
                            tabIndex={-1}
                        >
                            {isWorkedSolutionActivated
                                ? <CloseIcon><AppImage imageType={'svg'} iconType={'closeIcon'} /></CloseIcon>
                                : <AppImage imageType={'svg'} iconType={'workedSolutionIcon'} />
                            }
                        </IconSupportButton>
                    </Show>
                </>}
            </div>
            <div className={'right-footer'}>
                {!isDiagnostic && <AttemptStatus />}
                <SubmitButton
                    ref={checkBtnRef}
                    colorScheme={footerBtnColorScheme}
                    onClick={isDefined(validation) ? showNextQuestion : validateAnswer}
                    data-testid={'footer-button'}
                    isDisabled={checkButtonDisabled}
                    ml={5}
                    className={'check-button'}
                >
                    {footerBtnLabel}
                </SubmitButton>
            </div>
            {!isDiagnostic && conceptRefreshUrl &&
                <ModalClickviewVideo
                    url={conceptRefreshUrl}
                    contentType={ContentType.conceptRefresh}
                    {...chakraModalProps}
                    onClose={onCloseModalHandler}
                />}
        </Box>
    );
};

export default QuestionFooter;

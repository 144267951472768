import { css } from '@emotion/react';

import { AppThemeType } from './theme/app-theme';

export const baseAppStyle = (theme: AppThemeType) => css`
    html, body {
        font-family: ${theme.typography.fontFamily};
    }

    body {
        margin: 0;
        background-color: ${theme.app.appBgColour};
        overflow: hidden;
    }

    .application {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

        *, *::before, ::after {
          border-color: transparent;
          box-sizing: content-box;
        }

        .ML__keyboard.is-visible {
            width: auto;
    
            .MLK__backdrop {
                background-color: transparent;
                position: static;
                display: inline;
            }

            .MLK__plate {
                position: static;
                container-type: unset;
                background-color: white;
                border: 0;
                border-radius: 20px;
    
                .MLK__layer {
                    .MLK__toolbar {
                        display: none;
                    }
                    .MLK__rows {
                        padding-top: 16px;
                        padding-bottom: 20px;
                        margin-bottom: 0;

                        @media (max-width: ${theme.breakpoints.lg}) {
                            padding-bottom: 0;
                        }
    
                        .MLK__row {
                            height: 52px;
                            gap: 0;

                            & > div {
                                width: 52px;
                                height: 46px;
                                cursor: pointer;
                                background-color: white;
                                border-radius: 12px;
                                color: ${theme.app.primary900};
                                border: 1px solid ${theme.app.primary300};
                                font-size: 22px;

                                &:not(:last-child) {
                                    margin-right: 8px;
                                }

                                &:hover, &:active, &:focus {
                                    border-color: ${theme.colors.primary['500']};
                                }

                                &:active {
                                    background-color: ${theme.colors.primary['50']};
                                }

                                &.operator {
                                    background-color: ${theme.app.neutral100};
                                    border: 0;
                                    font-size: 32px;

                                    &:hover, &:active, &:focus {
                                        border: 1px solid ${theme.colors.primary['500']};
                                    }

                                    &:active {
                                        background-color: ${theme.colors.primary['100']};
                                    }
                                }

                                &.separator {
                                    border: 0;
                                }

                                &.action.font-glyph {
                                    font-size: 26px;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
            }
        }

        .question-set-type-0, .single-variant-preview-tests-container {
            .rotate-device-modal-content {
                margin-top: 175px;
            }
        }

        .single-variant-preview-container {
            .rotate-device-modal-content {
                margin-top: 165px;
            }
        }

        .root-container.question-set-type-0,
        .root-container.single-variant-preview-container {
            &[data-rotate-modal-active="true"] {
                & > :first-of-type {
                    z-index: 0;
                }
            }
        }
    }

    /*::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: white;
    }

    ::-webkit-scrollbar-track {
        background-color: ${theme.app.white01};
    }

    ::-webkit-scrollbar-thumb {
        background: lightgray;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: darkgrey;
    }*/

    // build version number, display after trooper build after deploy to demo|qa|live
    .version-number {
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 10;
        color: #aaa; // static color - no change required
        font-size: 9px;
    }

    .ML__keyboard.is-visible {
        width: 0;
        right: 0;
        left: auto;

        .MLK__backdrop {
            background-color: white;
            border-radius: 20px;
        }

        .MLK__toolbar {
            display: none;
        }

        .MLK__rows {
            padding-top: 32px;
            padding-bottom: 20px;

            .MLK__row {
                height: 52px;

                & > div {
                    width: 52px;
                    height: 46px;
                    background-color: white;
                    border-radius: 12px;
                    color: ${theme.app.primary900};
                    border: 1px solid ${theme.app.primary300};
                    font-size: 22px;
                    cursor: pointer;

                    &.operator {
                        background-color: ${theme.app.neutral100};
                        border: 0;
                        font-size: 32px;
                    }

                    &.action.font-glyph {
                        font-size: 26px;
                        font-weight: normal;
                    }
                }
            }
        }
    }
`;
